import { all, put, call, takeLatest, delay, select } from "redux-saga/effects";
import {
  CLIENT_ID_RENT_COOKIE,
  DO_LOGOUT_COOKIE,
  EXPIRES_IN_RENT_COOKIE,
  IMPERSONATED_CLIENT_COOKIE,
  JWT_RENT_COOKIE,
  REFRESH_TOKEN_RENT_COOKIE,
  setImpersonatedUser,
  setLoggedUser,
  setTokenUser,
  unsetLoggedUser,
} from "store/slices/auth/slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { logger } from "@tecma/logs-fe";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { LoginByProjectInput } from "client/modules/Auth";
import {
  IToken,
  IUser,
  LoginByProjectData,
  LoginByProjectDataAndId,
} from "utils/types/auth";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { AuthUtils } from "@tecma/auth-utils";
import i18n from "@tecma/i18n";
import {
  deleteSavedConfigurationsByProjectID,
  getBackUrl,
  getCookieOptions,
  setTokenCookies,
} from "utils/functions/auth";
import getDomain from "utils/functions/getDomain";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { authActions } from "./auth.actions";
import { getClient, getUserByJWT, login, refreshToken } from "./auth.api";
import { applicationActions } from "../application/application.actions";

function* getUserInfo(action: PayloadAction<string>): any {
  const userInfo = yield getUserByJWT(action.payload);
  yield put(setLoggedUser(userInfo));
  return userInfo;
}

function* loggedInStuff(action: PayloadAction<LoginByProjectDataAndId>): any {
  const { token, user, isConfigurationComplete, skipValidation } =
    action.payload;
  const cookieOptions = getCookieOptions();

  Cookies.set(JWT_RENT_COOKIE, token.accessToken, cookieOptions);
  Cookies.set(REFRESH_TOKEN_RENT_COOKIE, token.refreshToken, cookieOptions);
  Cookies.set(EXPIRES_IN_RENT_COOKIE, token.expiresIn, cookieOptions);
  Cookies.set(CLIENT_ID_RENT_COOKIE, user.client, cookieOptions);

  yield all([put(setLoggedUser(user)), put(setTokenUser(token))]);

  if (isConfigurationComplete) {
    yield put(applicationActions.saveSelectionsRequested({ skipValidation }));
  }
}

function* doLogin(action: PayloadAction<LoginByProjectInput>): any {
  const projectId = yield select(applicationStateSelectors.selectProjectID);
  const isNewLogin = yield select(applicationStateSelectors.isNewLogin);
  if (isNewLogin) {
    AuthUtils.authenticate(
      projectId,
      getBackUrl(),
      "SPACECONFIGURATOR",
      i18n.language,
    );
  } else {
    const { user, token } = yield login(action.payload);
    yield loggedInStuff({
      payload: { user, token, projectId },
      type: "auth/loggedInStuff",
    });
    applicationStateActions.closeAlert();
  }
}

function* doLogout(): Generator<any, void, any> {
  const isNewLogin = yield select(applicationStateSelectors.isNewLogin);
  const myAccountUrl = yield select(
    applicationStateSelectors.selectMyAccountUrl,
  );
  const projectID = yield select(applicationStateSelectors.selectProjectID);
  if (isNewLogin) {
    deleteSavedConfigurationsByProjectID(projectID);
    AuthUtils.logout(() => {}, myAccountUrl);
  } else {
    const domain = yield getDomain();

    Cookies.remove(JWT_RENT_COOKIE, { domain });
    Cookies.remove(REFRESH_TOKEN_RENT_COOKIE, { domain });
    Cookies.remove(EXPIRES_IN_RENT_COOKIE, { domain });
    Cookies.remove(CLIENT_ID_RENT_COOKIE, { domain });
  }
  yield put(unsetLoggedUser());
}

function* setNewCookies() {
  if (
    Cookies.get(JWT_RENT_COOKIE) &&
    Cookies.get(REFRESH_TOKEN_RENT_COOKIE) &&
    Cookies.get(EXPIRES_IN_RENT_COOKIE)
  ) {
    const token: IToken = {
      accessToken: Cookies.get(JWT_RENT_COOKIE) ?? "",
      refreshToken: Cookies.get(REFRESH_TOKEN_RENT_COOKIE) ?? "",
      expiresIn: Cookies.get(EXPIRES_IN_RENT_COOKIE) ?? "",
    };
    yield put(setTokenUser(token));
  }
}

function* getNewToken(
  timeout: number,
  baseTimeout: number,
  token: IToken,
  user: IUser,
): any {
  const logout = Cookies.get(DO_LOGOUT_COOKIE);
  const { refreshToken: newRefreshToken, expiresIn } = token;

  try {
    if (
      logout === "false" &&
      user &&
      baseTimeout >= 0 &&
      newRefreshToken &&
      expiresIn === Cookies.get(EXPIRES_IN_RENT_COOKIE)
    ) {
      const response = yield refreshToken(newRefreshToken, user.email);
      try {
        if (response) {
          const refreshedToken: IToken = response;
          yield put(setTokenUser(refreshedToken));
          setTokenCookies(refreshedToken);
        }
      } catch (error) {
        yield put(authActions.doLogout());
      }
    } else if (
      logout === "false" &&
      baseTimeout > 0 &&
      expiresIn !== Cookies.get(EXPIRES_IN_RENT_COOKIE)
    ) {
      yield setNewCookies();
    } else if (baseTimeout < 0 || logout === "true") {
      yield put(authActions.doLogout());
    }
  } catch (e) {
    logger.error(e);
    yield delay(timeout);
    yield getNewToken(timeout, baseTimeout, token, user);
  }
}

function* startCountDown(action: PayloadAction<LoginByProjectData>) {
  const { token, user } = action.payload;
  const targetTime =
    DateTime.fromISO(token.expiresIn).toMillis() -
    Number(process.env.REACT_APP_TIMEOUT_TOKEN);
  const baseTimeout = targetTime - Date.now();
  const maxTimeoutValue = 2147483647;
  const timeout = baseTimeout > maxTimeoutValue ? maxTimeoutValue : baseTimeout;
  if (timeout > 0) {
    yield delay(timeout);
    if (Cookies.get(DO_LOGOUT_COOKIE) === "false") {
      yield getNewToken(timeout, baseTimeout, token, user);
    }
  } else if (token && token.accessToken && token.expiresIn) {
    if (DateTime.fromISO(token.expiresIn) < DateTime.now()) {
      yield put(authActions.doLogout());
    }
  }
}

function* initAuth(action: PayloadAction<LoginByProjectDataAndId>): any {
  const { user, token, projectId } = action.payload;
  const logout = Cookies.get(DO_LOGOUT_COOKIE);
  if (logout === "true" && user) {
    yield put(authActions.doLogout());
  }
  // set auth info for logged in user
  if (
    Cookies.get(JWT_RENT_COOKIE) &&
    Cookies.get(REFRESH_TOKEN_RENT_COOKIE) &&
    Cookies.get(EXPIRES_IN_RENT_COOKIE) &&
    (!token || !user)
  ) {
    const newToken: IToken = {
      accessToken: Cookies.get(JWT_RENT_COOKIE) ?? "",
      refreshToken: Cookies.get(REFRESH_TOKEN_RENT_COOKIE) ?? "",
      expiresIn: Cookies.get(EXPIRES_IN_RENT_COOKIE) ?? "",
    };
    yield put(setTokenUser(newToken));
    setTokenCookies(newToken);
    try {
      yield call(getUserInfo, {
        payload: projectId,
        type: "auth/getUserByJWT",
      });
      if (Cookies.get(IMPERSONATED_CLIENT_COOKIE)) {
        const clientInfo = yield getClient(projectId);
        yield put(
          setImpersonatedUser({ ...clientInfo, client: clientInfo.id }),
        );
      }
    } catch (error) {
      yield put(authActions.doLogout());
    }
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.initAuth, initAuth);
  yield takeLatest(authActions.getUserByJWT, getUserInfo);
  yield takeLatest(authActions.doLogin, doLogin);
  yield takeLatest(authActions.doLogout, doLogout);
  yield takeLatest(authActions.loggedInStuff, loggedInStuff);
  yield takeLatest(authActions.startCountDown, startCountDown);
}
